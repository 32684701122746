import UrlTo from 'commons/js/util/UrlTo'
import { ZENDESK_URL, RENTAL_CAR_API, USER_SERVICE_API_URL, APPLICATION, MUTUAMAS_VIAT_FAQS_URL, MUTUAMAS_CASHBACK_URL } from 'commons/js/constants'
import defaultLanguage from 'commons/js/locale'

export const getZendeskUrl = (lang) => {
    const zendeskLang = lang
        ? lang.toLowerCase()
        : defaultLanguage.toLowerCase()
    return ZENDESK_URL + zendeskLang
}

const Routes = {
    base () {
        return window && window.location && window.location.origin || ''
    },
    apiUserServiceTerms () {
        return USER_SERVICE_API_URL + '/legal-docs/terms-and-conditions'
    },
    apiUserServiceRegister () {
        return USER_SERVICE_API_URL + '/users'
    },
    apiUserServiceRegisterIntent () {
        return USER_SERVICE_API_URL + '/users/registration-intent'
    },
    apiCarRentalGetPois ({ search }) {
        return RENTAL_CAR_API + '/v1/pois/list' + (search ? '?q=' + search : '')
    },
    apiCarRentalClickToCall () {
        return UrlTo.getApi('api/click-to-call/request')
    },
    reloadRentalCarSearch () {
        return RENTAL_CAR_API + '/v1/rates/reloadSearch'
    },
    createRentalCarContract () {
        return RENTAL_CAR_API + '/v1/bookings/create'
    },
    apiCarRentalGetRates ({searchId}) {
        if (searchId) {
            return RENTAL_CAR_API + '/v1/rates/filter' + '?idSearch=' + searchId
        }
        return RENTAL_CAR_API + '/v1/rates/'
    },
    apiCarRentalGetBooking ({id}) {
        return `${RENTAL_CAR_API}/v1/bookings/${id}`
    },
    apiCarRentalBuildBooking ({id}) {
        if (id) {
            return `${RENTAL_CAR_API}/v1/bookings/build/${id}`
        }
        return RENTAL_CAR_API + '/v1/bookings/build'
    },
    apiCarRentalCancelBooking ({id}) {
        return `${RENTAL_CAR_API}/v1/bookings/${id}/cancel`
    },
    serTicketUnParkedPath (params) {
        return UrlTo.get('ser-ticket/check-unpark', params)
    },
    keycloakLoginSuccessPath () {
        const base = window && window.location && window.location.origin || ''
        return base + (UrlTo.base ? UrlTo.base : '') + '/keycloak/login-success'
    },
    privacyPath (params) {
        return UrlTo.get('glovie/privacy', params)
    },
    userProfilePage (params) {
        return UrlTo.get('account/edit', params)
    },
    howToPayAFine (params) {
        return UrlTo.get('driver-plus/landing-fines', params)
    },
    preferencesPath (params) {
        return UrlTo.get('glovie/preferences', params)
    },
    securityPath (params) {
        return UrlTo.get('account/security', params)
    },
    mobesPath (params) {
        return UrlTo.get('mobe/page', params)
    },
    tollsPath (params) {
        return UrlTo.get('toll/page', params)
    },
    glovieFinesPath (params) {
        return UrlTo.get('glovie/fines', params)
    },
    homePath (params, language, country, flavor) {
        return UrlTo.get('site/index', params, language, country, flavor)
    },
    homeEnterprisePath (params, language, country) {
        return UrlTo.get('site/landing-enterprise', params, language, country)
    },
    getHomeViaTPath (params, language, country) {
        return UrlTo.get('site/viat', params, language, country)
    },
    getHomeWorkshopsPath (params, language, country) {
        return UrlTo.get('site/landing-workshop', params, language, country)
    },
    finePortalPath () {
        return UrlTo.get('fine-portal/index')
    },
    finePortalAtlantaPath (params) {
        return UrlTo.get('fine-portal/atlanta', params)
    },
    finePortalWithTown(params) {
        return UrlTo.get('fine-portal/portal-with-town', params)
    },
    finePortalWithFinePath (params) {
        return UrlTo.get('fine-portal/index', params)
    },

    loginPath (params) {
        return UrlTo.get('account/login', params)
    },

    requestPasswordPath (params) {
        return UrlTo.get('account/request-password-reset', params)
    },

    termsAndConditionsPath (params, lang, country) {
        return UrlTo.get('site/terms', params, lang, country)
    },

    cookiesPath (params, lang, country) {
        return UrlTo.get('site/terms-cookies', params, lang, country)
    },

    zendeskPath (lang) {
        return getZendeskUrl(lang)
    },

    zendeskNewRequest (lang) {
        return getZendeskUrl(lang) + '/requests/new'
    },

    zendeskViatDeviceInstructions (lang) {
        return getZendeskUrl(lang) + '/articles/115000188950-C%C3%B3mo-debo-colocar-mi-dispositivo-Via-T'
    },

    zendeskViatDeviceNotWorking (lang) {
        return getZendeskUrl(lang) + '/articles/115000196424-Mi-Via-T-no-funciona-y-emite-dos-pitidos-Está-bloqueado'
    },

    viatFaqs (lang) {
        if (APPLICATION === 'mutuamas') {
            return MUTUAMAS_VIAT_FAQS_URL
        }

        return getZendeskUrl(lang) + '/sections/115000077190-Via-T'
    },

    cashbackInfoURL () {
        return MUTUAMAS_CASHBACK_URL
    },

    onBoardingPath (params) {
        return UrlTo.get('account/on-boarding', params)
    },

    parkingPath (params) {
        return UrlTo.get('parking/show', params)
    },

    vehicleInspectionStationPath (params) {
        return UrlTo.get('vehicle-inspection-station/show', params)
    },

    poiEventsPath (params) {
        return UrlTo.get('poi/show-events', params)
    },

    parkingsSearchPath (params) {
        return UrlTo.get('parking/list', params)
    },

    vehicleInspectionStationSearchPath (params) {
        return UrlTo.get('vehicle-inspection-station/list', params)
    },

    chargingPointSearchPath (params) {
        return UrlTo.get('charging-point/list', params)
    },

    bookingProcessPath (params) {
        return UrlTo.get('booking/process', params)
    },

    seasonTicketProcessPath (params) {
        return UrlTo.get('season-ticket/contract', params)
    },

    seasonTicketContractNewPath (params) {
        return UrlTo.get('season-ticket-contract/new', params)
    },

    offeredParkingPassContractPath (params) {
        return UrlTo.get('offered-parking-pass/contract', params)
    },

    bookedParkingPassNewPath (params) {
        return UrlTo.get('booked-parking-pass/new', params)
    },

    workshopBookingSelectionPath (params) {
        return UrlTo.get('workshop-booking/selection', params)
    },

    workshopBookingSelectionPath (params) {
        return UrlTo.get('workshop-booking/selection', params)
    },

    getNumberPlatesWhitelistFile (params) {
        return UrlTo.get('wallet-corporate-plates-whitelist/download', params)
    },

    apiWorkshopBookingUpdateProductPath (params) {
        return UrlTo.getApi('api/workshop-booking/assign-products', params)
    },

    apiWorkshopBookingUpdateDates (params) {
        return UrlTo.getApi('api/workshop-booking/set-appointment-date', params)
    },

    apiWorkshopBookingAssignWorkshopPath (params) {
        return UrlTo.getApi('api/workshop-booking/assign-workshop', params)
    },
    apiGetFinePrices (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-price/get-prices', params)
    },
    apiGetFinesNotifierSubscriptionGetAllActive (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-subscriptions/get-all-active', params)
    },
    apiGetFinesNotifierSubscriptionCreateForDni (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-subscriptions/create-for-dni', params)
    },
    apiGetFinesNotifierSubscriptionCreateForNumberPlate (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-subscriptions/create-for-number-plate', params)
    },
    apiFinesNotifierSubscriptionUpdateLicenseDate (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-subscriptions/update-license-date', params)
    },
    apiFinesNotifierSubscriptionCancel (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-subscriptions/cancel', params)
    },
    apiFinesNotifierNotificationsGetPointReport (params) {
        return UrlTo.getMobileApi('v1/fines-notifier-notifications/get-points-report', params)
    },
    apiCreateWorkshopBooking (params) {
        return UrlTo.getApi('api/workshop-booking/create', params)
    },

    apiConfirmWorkshopBooking (params) {
        return UrlTo.getApi('api/workshop-booking/confirm-appointment', params)
    },

    workshopBookingProcessPath (params) {
        return UrlTo.get('workshop-booking/process', params)
    },

    newWorkshopBookingPath (params) {
        return UrlTo.get('workshop-booking/new', params)
    },

    seasonTicketContractPath (params) {
        return UrlTo.get('season-ticket-contract/show', params)
    },

    apiGetWorkshopBookingOccupation (params) {
        return UrlTo.getApi('api/workshops/availability', params)
    },

    viatLadingHowItsWorksPath () {
        return UrlTo.get('site/viat-how-it-works')
    },

    viatInvoiceDownload (params) {
        return UrlTo.get('shop/shop/invoice', params)
    },

    viatShopPath (params) {
        return UrlTo.get('viat-shop/shop', params)
    },

    vehicleInspectionBookingProcessPath (params) {
        return UrlTo.get('vehicle-inspection-booking/process', params)
    },

    vehicleInspectionBookingNewPath (params) {
        return UrlTo.get('glovie/show-itv-booking-new', params)
    },

    newBookingPath (params) {
        return UrlTo.get('booking/new', params)
    },

    viatNewOrderPath (params) {
        return UrlTo.get('viat-shop/new', params)
    },

    walletInvoiceData (params) {
        return UrlTo.get('wallet/data', params)
    },

    invoicePagePath (params) {
        return UrlTo.get('invoice/page', params)
    },

    serTicketShowPage (params) {
        return UrlTo.get('ser-ticket/show-detail', params)
    },

    serTicketProcessPath (params) {
        return UrlTo.get('ser-ticket/pay', params)
    },

    serTicketNewPath (params) {
        return UrlTo.get('ser-ticket/new', params)
    },

    serTicketSend (params) {
        return UrlTo.get('ser-ticket/send-email', params)
    },

    rotationShowPage (params) {
        return UrlTo.get('glovie/show-rotation', params)
    },

    workshopBookingShowPage (params) {
        return UrlTo.get('glovie/show-workshop-booking', params)
    },

    bookingShowPage (params) {
        return UrlTo.get('booking/show', params)
    },

    vehicleInspectionBookingPath (params) {
        return UrlTo.get('glovie/show-itv-booking', params)
    },

    pagateliaOrderPath (params) {
        return UrlTo.get('glovie/pagatelia-order', params)
    },

    pagateliaOrdersPath (params) {
        return UrlTo.get('order/page', params)
    },

    walletPath (params) {
        return UrlTo.get('glovie/wallet', params)
    },

    walletDetailPath (params) {
        return UrlTo.get('wallet/show', params)
    },

    glovieWalletBeneficiariesPath (params) {
        return UrlTo.get('wallet/beneficiaries', params)
    },

    chargingPointBookingPath (params) {
        return UrlTo.get('glovie/show-charging-point-booking', params)
    },

    parkingPassShowPage (params) {
        return UrlTo.get('booked-parking-pass/show', params)
    },

    seasonTicketShowPage (params) {
        return UrlTo.get('season-ticket-contract/show', params)
    },

    glovieActivesPath (params) {
        return UrlTo.get('glovie/list-actives', params)
    },

    glovieInactivesPath (params) {
        return UrlTo.get('glovie/list-inactives', params)
    },

    glovieVehiclesPath (params) {
        return UrlTo.get('glovie/vehicles-page', params)
    },

    glovieTrailersPath (params) {
        return UrlTo.get('glovie/trailers-page', params)
    },

    couponSamplePath (params) {
        return UrlTo.get('merchant/sample-coupon', params)
    },

    showMerchantCouponOrder (params) {
        return UrlTo.get('merchant/order', params)
    },

    driverPlusSitePath (params) {
        return UrlTo.get('driver-plus/show', params)
    },

    driverPlusContractPath (params) {
        return UrlTo.get('driver-plus/contract', params)
    },

    glovieDriverPlusSubscriptionsPath (params) {
        return UrlTo.get('glovie/driver-plus-subscriptions', params)
    },

    glovieDriverPlusFinesPath (params) {
        return UrlTo.get('glovie/driver-plus-fines', params)
    },

    rentalCarSitePath (params) {
        return UrlTo.get('rental-car/show', params)
    },

    rentalCarContractPath (params) {
        return UrlTo.get('rental-car/contract', params)
    },

    rentalCarContractNewPath (params) {
        return UrlTo.get('rental-car/new', params)
    },

    rentalCarContractDetailPath (params) {
        return UrlTo.get('rental-car/detail', params)
    },

    rentalCarSitePathMobile (params) {
        return UrlTo.get('rental-car/search', params)
    },

    apiBookingPath ({idBooking}) {
        return UrlTo.getMobileApi('v1/users/me/bookings/' + idBooking)
    },

    apiSerTicketPath (params) {
        return UrlTo.getApi('api/ser-ticket/show', params)
    },

    apiSerTicketUnParkInfoPath (params) {
        return UrlTo.getApi('api/ser-ticket/get-un-park-info', params)
    },

    apiUnParkSerTicketPath (params) {
        return UrlTo.getApi('api/ser-ticket/un-park', params)
    },

    apiSeasonTicketPricePath (params) {
        return UrlTo.getApi('api/season-ticket/get-price', params)
    },

    apiOfferedParkingPassPath ({ parkingId, passId }) {
        return UrlTo.getMobileApi(`v1/parkings/${parkingId}/offered-parking-passes/${passId}`)
    },

    apiWorkshopBookingPath (params) {
        return UrlTo.getApi('api/workshop-booking/get', params)
    },

    apiSuitableBookingWorkshops (params) {
        return UrlTo.getApi('api/workshops/index', params)
    },

    apiGetSerTowns (params) {
        return UrlTo.getApi('api/ser-town/list', params)
    },

    apiRotationPath (params) {
        return UrlTo.getApi('api/rotation/view', params)
    },

    apiCancelBookingPath ({idBooking}) {

        return UrlTo.getMobileApi('v1/users/me/bookings/' + idBooking)
    },

    apiSeasonTicketContractPath (params) {
        return UrlTo.getMobileApi(`v1/users/me/season-ticket-contracts/${params.id}/with-payments`)
    },

    apiCancelSeasonTicketContractPath (params) {
        return UrlTo.getMobileApi(`v1/users/me/season-ticket-contracts/${params.idContract}/cancel-with-payments`)
    },

    apiUpdateVehicleSeasonTicketContractPath (params) {
        return UrlTo.getMobileApi(`v1/users/me/season-ticket-contracts/${params.id}/vehicle/${params.vehicleId}/with-payments`)
    },

    apiCreateSeasonTicketContract () {
        return UrlTo.getMobileApi(`v1/users/me/season-ticket-contracts`)
    },

    apiSeasonTicketPricePath (params) {
        return UrlTo.getMobileApi(`v1/season-ticket/${params.seasonTicketId}/start/${params.startDate}/price`)
    },

    apiCheckSeasonTicketAvailability (params) {
        return UrlTo.getMobileApi(`v1/users/me/season-tickets/${params.seasonTicketId}/vehicle/${params.vehicleId}/start/${params.startDate}/availability`)
    },

    apiCancelWorkshopBookingPath (params) {
        return UrlTo.getApi('api/workshop-booking/cancel', params)
    },

    apiCancelBookedParkingPassPath ({ passId }) {
        return UrlTo.getMobileApi('v1/users/me/booked-parking-pass/' + passId)
    },

    apiCancelChargingPointBookingPath (params) {
        return UrlTo.getApi('api/charging-point-bookings/cancel', params)
    },

    apiSearchParkings (params) {
        return UrlTo.getMobileApi('v1/public-parkings/search', params)
    },

    apiSearchAirports (params) {
        return UrlTo.getMobileApi(`v1/airports`, params)
    },

    apiSearchChargingPoints (params) {
        return UrlTo.getApi('api/charging-points/index', params)
    },

    apiSearchPoisWithEvents (params) {
        return UrlTo.getMobileApi('v1/point-of-interest/list-with-events', params)
    },

    apiFetchAirport (params) {
        return UrlTo.getApi('api/airports/view', params)
    },
    apiGetBookingAvailability (params) {
        return UrlTo.getMobileApi(`v1/parkings/${params.idParking}/public-bookings/availability`, params)
    },

    apiGetBookingProductsAvailability (params) {
        return UrlTo.getMobileApi(`v1/parkings/${params.idParking}/public-bookings/availability/products`, params)
    },

    getAcceptedCountries () {
        return UrlTo.getApi('api/country/accepted-countries')
    },

    getTOS () {
        return UrlTo.getApi('api/gdpr-conditions/get-terms-to-approve')
    },

    acceptTOS () {
        return UrlTo.getApi('api/gdpr-conditions/approve')
    },

    setPrivacy () {
        return UrlTo.getApi('api/privacy/create-update')
    },

    apiGetParking ({ idParking }) {
        return UrlTo.getMobileApi(`v1/parkings/${idParking}`)
    },

    login () {
        return UrlTo.getApi('api/account/login')
    },

    loginKeycloak () {
        return UrlTo.getApi('api/keycloak/login')
    },

    apiLogout () {
        return UrlTo.getApi('api/user/log-out')
    },

    apiCheckSerFine (params) {
        return UrlTo.getApi('api/ser-fine/check', params)
    },

    apiPaySerFine (params) {
        return UrlTo.getApi('api/ser-fine/pay', params)
    },

    apiPaySerTicket () {
        return UrlTo.getApi('api/ser-ticket/pay')
    },

    apiGetUser () {
        return UrlTo.getApi('api/user/show')
    },

    apiGetUserWallets () {
        return UrlTo.getApi('api/wallet/user-wallets')
    },

    apiCheckUserExists (params) {
        return UrlTo.getApi('api/user/check-user-exists', params)
    },

    apiCreatePublicBooking () {
        return UrlTo.getMobileApi(`v1/public-bookings`)
    },

    apiCreateBooking () {
        return UrlTo.getMobileApi('v1/users/me/bookings/create-web-logged-user-booking')
    },

    apiGetVehicles () {
        return UrlTo.getApi('api/vehicles/index')
    },

    apiGetPagedVehicles (params) {
        return UrlTo.getApi('api/vehicles/paginated', params)
    },

    apiUpdateVehicle (params) {
        return UrlTo.getApi('api/vehicles/update', params)
    },

    apiDeleteVehicle (params) {
        return UrlTo.getApi('api/vehicles/delete', params)
    },

    apiCreateVehicle (params) {
        return UrlTo.getApi('api/vehicles/create', params)
    },

    apiGetPagedTrailers (params) {
        return UrlTo.getApi('api/trailers/paginated', params)
    },

    apiDeleteTrailer (params) {
        return UrlTo.getApi('api/trailers/delete', params)
    },

    apiCreateTrailer (params) {
        return UrlTo.getApi('api/trailers/create', params)
    },

    apiRequestUserPricePerWallet (params) {
        return UrlTo.getApi('api/wallet-corporate/price-per-beneficiary', params)
    },

    apiCreateWalletBeneficiary (params) {
        return UrlTo.getApi('api/wallet-corporate/add-beneficiary', params)
    },

    apiDeleteWalletBeneficiary (params) {
        return UrlTo.getApi('api/wallet-corporate/remove-beneficiary', params)
    },

    apiCreateDeposit (params) {
        return UrlTo.getApi('api/wallet/create-deposit', params)
    },


    apiCreateBookedParkingPass ({params}) {
        return UrlTo.getMobileApi('v1/users/me/booked-parking-pass', params)
    },

    apiGetVehicleBrands (params) {
        return UrlTo.getApi('api/vehicles/brands', params)
    },

    apiGetVehicleColors () {
        return UrlTo.getApi('api/vehicles/colors')
    },

    apiCreatePrebooking () {
        return UrlTo.getMobileApi('v1/users/me/prebookings')
    },

    apiCreatePublicPrebooking () {
        return UrlTo.getMobileApi('v1/prebookings')
    },
    apiGetPrebooking (params) {
        return UrlTo.getMobileApi(`v1/prebookings/${params.idPrebooking}`, params)
    },

    apiUpdatePrebooking (params) {
        return UrlTo.getMobileApi(`v1/prebookings/${params.idPrebooking}`, params)
    },

    apiCreateAccount (params) {
        return UrlTo.getApi('api/account/create', params)
    },

    apiSendContactForm (params) {
        return UrlTo.getApi('api/contact/send-form', params)
    },

    apiCompleteProfile (params) {
        return UrlTo.getApi('api/user/on-boarding', params)
    },

    apiCreateSetupIntent (params) {
        return UrlTo.getApi('api/credit-card/create-setup-intent', params)
    },

    apiRequestInvoices ({ idUser, ...params }) {
        return UrlTo.getMobileApi(`v1/users/${idUser}/invoices`, params)
    },

    apiRequestInvoiceXlsx (params) {
        return UrlTo.getApi('api/invoices/xlsx', params)
    },

    apiRequestProvinces (params) {
        return UrlTo.getApi('api/provinces/list', params)
    },

    apiGetProvincesForShop (params) {
        return UrlTo.getApi('api/provinces/list-for-shop', params)
    },

    apiRequestTownsByProvince (params) {
        return UrlTo.getApi('api/provinces/towns-by-province', params)
    },

    apiReportClaim (params) {
        return UrlTo.getApi('api/parking-fine-usa/notify-claim', params)
    },

    apiRequestWalletInvoiceData (params) {
        return UrlTo.getApi('api/wallet-invoice-data/view', params)
    },

    apiUpdateWalletInvoiceData (params) {
        return UrlTo.getApi('api/wallet-invoice-data/update', params)
    },

    apiRequestWalletData (params) {
        return UrlTo.getApi('api/wallet/fetch', params)
    },

    apiGetWalletBeneficiaries (params) {
        return UrlTo.getApi('api/wallet-corporate/beneficiaries-list', params)
    },

    apiGetWalletBeneficiariesAll (params) {
        return UrlTo.getApi('api/wallet-corporate/get-all-beneficiaries', params)
    },

    apiGetActiveContracts (params) {
        return UrlTo.getApi('api/contracts/index-by-user', params)
    },

    apiGetInactiveContracts (params) {
        return UrlTo.getApi('api/contracts/old-by-user', params)
    },

    apiCheckExpandedFine (params) {
        return UrlTo.getApi('api/parking-fine-usa/check-fine-with-extra-info', params)
    },

    apiPayExpandedFine (params) {
        return UrlTo.getApi('api/parking-fine-usa/cancel-parking-fine', params)
    },

    apiRequestChargingPointBooking (params) {
        return UrlTo.getApi('api/charging-point-bookings/view', params)
    },

    apiRequestVehicleInspectionBooking ({ bookingId, ...params }) {
        return UrlTo.getMobileApi(`v1/users/me/vehicle-inspection-bookings/${bookingId}`, params)
    },

    apiCancelVehicleInspectionBookingPath ({ bookingId, ...params }) {
        return UrlTo.getMobileApi(`v1/users/me/vehicle-inspection-bookings/${bookingId}`, params)
    },

    apiSearchVehicleInspectionStation (params) {
        return UrlTo.getMobileApi('v1/vehicle-inspection-stations/index', params)
    },

    apiRequestVehicleInspectionProducts ({ stationId, ...params }) {
        return UrlTo.getMobileApi(`v1/vehicle-inspection-station-products/station/${stationId}`, params)
    },

    apiGetVehicleInspectionStation ({ stationId, ...params }) {
        return UrlTo.getMobileApi(`v1/vehicle-inspection-stations/${stationId}`, params)
    },

    apiGetVehicleInspectionStationOccupationForDays ({ stationId, stationProductId, ...params }) {
        return UrlTo.getMobileApi(`v1/vehicle-inspection-stations/${stationId}/${stationProductId}/occupation/days`, params)
    },

    apiGetVehicleInspectionStationOccupationIntraDay ({ stationId, stationProductId, ...params }) {
        return UrlTo.getMobileApi(`v1/vehicle-inspection-stations/${stationId}/${stationProductId}/occupation/intra-day`, params)
    },

    apiVehicleInspectionCreateNewUserBooking () {
        return UrlTo.getMobileApi('v1/public-vehicle-inspection-bookings')
    },

    apiVehicleInspectionCreateExistingUserBooking () {
        return UrlTo.getMobileApi('v1/users/me/vehicle-inspection-bookings/logged')
    },

    apiRequestChangeEmail (params) {
        return UrlTo.getApi('api/user/email-update-request', params)
    },

    apiUpdatePassword (params) {
        return UrlTo.getApi('api/user/update-password', params)
    },

    apiGetEnvironmentalLabelNumber (params) {
        return UrlTo.getApi('api/vehicles/get-environmental-label', params)
    },
    apiSavePreferences (params) {
        return UrlTo.getApi('api/user-preferences/update', params)
    },
    apiGetPrivacy () {
        return UrlTo.getApi('api/privacy/get')
    },
    apiGetViatParkingsByCountry (params) {
        return UrlTo.getApi('api/parkings/index-by-opening-mode-viat-and-country', params)
    },
    apiViatHighwayDiscounts () {
        return UrlTo.getApi('api/viat/highway-discounts')
    },
    apiGetCreditCards () {
        return UrlTo.getApi('api/credit-card/list')
    },
    apiGetCreditCardInfo (params) {
        return UrlTo.getApi('api/credit-card/info', params)
    },
    apiCreateCreditCard (params) {
        return UrlTo.getApi('api/credit-card/create', params)
    },
    apiDeleteCreditCard (params) {
        return UrlTo.getApi('api/credit-card/delete', params)
    },
    apiSetPagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/update', params)
    },
    apiGetPagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/get', params)
    },
    apiSendDownloadMovementsToEmail (params) {
        return UrlTo.getApi('api/wallet/request-movements-csv', params)
    },
    apiGetGlobalConfig () {
        return UrlTo.getApi('api/config/get')
    },
    apiGetFinePortalTowns (params) {
        return UrlTo.getApi('api/town/all-having-fines', params)
    },
    apiCreatePagateliaOrder (params) {
        return UrlTo.getApi('api/viat-order/create', params)
    },
    apiUpdatePagateliaOrder (params) {
        return UrlTo.getApi('api/viat-order/update', params)
    },
    apiGetViatProducts (params) {
        return UrlTo.getApi('api/viat-products/get-price', params)
    },
    apiPayNewUserViatOrder (params) {
        return UrlTo.getApi('api/viat-order/pay-new-user-order', params)
    },
    apiPayExistingUserViatOrder (params) {
        return UrlTo.getApi('api/viat-order/pay-logged-user-order', params)
    },
    apiConfirmPaymentIntent (params) {
        return UrlTo.getApi('api/wallet/confirm-payment-intent', params)
    },
    apiGetMobes (params) {
        return UrlTo.getApi('api/mobe/list', params)
    },
    apiGetTolls (params) {
        return UrlTo.getApi('api/toll/list', params)
    },
    apiGetSerSteps (params) {
        return UrlTo.getApi('api/ser-step/list', params)
    },
    apiGetSerZones (params) {
        return UrlTo.getApi('api/ser-zone/list', params)
    },
    apiGetEnabledMobes (params) {
        return UrlTo.getApi('api/mobe/list-enabled', params)
    },
    apiBindWalletToMobe (params) {
        return UrlTo.getApi('api/mobe/bind-wallet', params)
    },
    apiBookingUpdateVehicle ({idBooking}) {
        return UrlTo.getMobileApi(`v1/users/me/bookings/${idBooking}/update-vehicle`)
    },

    apiBindVehicleToMobe (params) {
        return UrlTo.getApi('api/mobe/bind-vehicle', params)
    },
    apiUnbindVehicleToMobe (params) {
        return UrlTo.getApi('api/mobe/unbind-vehicle', params)
    },
    apiUnassignMobe (params) {
        return UrlTo.getApi('api/mobe/unassign', params)
    },
    apiGetPagateliaOrdersData (params) {
        return UrlTo.getApi('api/viat-order/list', params)
    },
    apiDeletePagateliaOrderData (params) {
        return UrlTo.getApi('api/viat-order/cancel', params)
    },
    apiGetTwoFaActiveMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/get-active-method', params)
    },
    apiVehicleUpdateKm (params) {
        return UrlTo.getApi('api/vehicles/update-km', params)
    },
    apiActiveTwoFaActiveEmailMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/activate-email-method', params)
    },
    apiCheckAdvancedVehiclesAdmin (params) {
        return UrlTo.getApi('api/user/check-advanced-vehicles-admin', params)
    },
    apiUploadVehicleCsvFile (params) {
        return UrlTo.getApi('api/vehicles/upload-number-plates-csv', params)
    },
    apiUploadTrailerCsvFile (params) {
        return UrlTo.getApi('api/trailers/upload-number-plates-csv', params)
    },
    apiUploadNumberPlateWhitelistCsvFile (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/upload-number-plates-csv', params)
    },
    apiUploadWalletBeneficiaryCsvFile (params) {
        return UrlTo.getApi('api/wallet-corporate/add-beneficiaries-from-csv', params)
    },
    apiDeactivateTwoFaActiveEmailMethod (params) {
        return UrlTo.getApi('api/two-factor-authentication/deactivate-email-method', params)
    },
    apiGetBookingAvailabilityUpdateDates ({idBooking, ...restParams}) {
        return UrlTo.getMobileApi(`v1/users/me/bookings/${idBooking}/availability/update-dates`, {idBooking, ...restParams})
    },
    apiUpdateUserData (params) {
        return UrlTo.getApi('api/user/update', params)
    },
    updateBookingDates ({idBooking, ...restParams}) {
        return UrlTo.getMobileApi(`v1/users/me/bookings/${idBooking}/update-dates`, {...restParams})
    },
    apiGetWalletPreferences (params) {
        return UrlTo.getApi('api/wallet-preferences/index', params)
    },
    apiAssociateCreditCard (params) {
        return UrlTo.getApi('api/wallet/associate-credit-card', params)
    },
    apiUpdateWalletPreferences (params) {
        return UrlTo.getApi('api/wallet-preferences/update', params)
    },
    apiUpdateNumberPlatesWhitelistEnabled (params) {
        return UrlTo.getApi('api/wallet/update-plates-whitelist-enabled', params)
    },
    apiGetPagedCorporateWhitelistedNumberPlates (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/search-by-plate', params)
    },
    apiDeleteCorporateWhitelistedNumberPlate (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/delete-number-plate', params)
    },
    apiDeleteAllCorporateWhitelistedNumberPlates (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/delete-all', params)
    },
    apiCreateCorporateWhitelistedNumberPlate (params) {
        return UrlTo.getApi('api/wallet-corporate-plates-whitelist/add-number-plate', params)
    },
    apiGetAutoDepositTiers (params) {
        return UrlTo.getApi('api/deposit-tier/get-auto-deposit-tiers', params)
    },
    apiGetDepositTiers (params) {
        return UrlTo.getApi('api/deposit-tier/get-deposit-tiers', params)
    },
    apiGetWalletMovements (params) {
        return UrlTo.getApi('api/wallet/movements', params)
    },
    apiRequestResetPassword (params) {
        return UrlTo.getApi('api/account/request-reset-password', params)
    },
    apiResetPasswordUpdate (params) {
        return UrlTo.getApi('api/account/reset-password-update', params)
    },
    apiGetBookedParkingPass({passId}) {
        return UrlTo.getMobileApi('v1/users/me/booked-parking-pass/' + passId)
    },
    apiGetCookies () {
        return UrlTo.getApi('api/cookies/index')
    },
}

export default Routes
